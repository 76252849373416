






































import {
  SfTabs, SfCheckbox, SfButton, SfLink,
} from '@storefront-ui/vue';
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';

export default defineComponent({
  name: 'MyNewsletter',
  components: {
    SfTabs,
    SfCheckbox,
    SfButton,
    SfLink,
  },
  setup() {
    const {
      user,
      updateUser,
      isAuthenticated,
    } = useUser();

    const isSubscribed = ref<boolean>(!!user.value.is_subscribed);

    const saveForm = async () => {
      if (isAuthenticated.value && !!user.value.email) {
        await updateUser({ user: { is_subscribed: isSubscribed.value } });
      }
    };

    return {
      isSubscribed,
      saveForm,
    };
  },
});
